import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "con-layout-topbar" }
const _hoisted_2 = { class: "content-header flex items-center justify-center pl-2 lg:pl-0 md:pl-0 md:justify-between sm:px-0 lg:justify-between flex-auto" }
const _hoisted_3 = { class: "flex items-center" }
const _hoisted_4 = { class: "text-white md:text-base text-sm text-center lg:text-left md:text-left" }
const _hoisted_5 = {
  key: 0,
  class: "ml-4"
}
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "text-white font-bold text-sm" }
const _hoisted_8 = { class: "lg:right-header md:right-header flex items-center" }
const _hoisted_9 = { class: "biodata mr-3 text-right lg:block md:block hidden" }
const _hoisted_10 = { class: "name-profile text-white" }
const _hoisted_11 = { class: "text-xs text-white" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ProfileDropdown = _resolveComponent("ProfileDropdown")!

  return (_openBlock(), _createElementBlock("header", {
    class: _normalizeClass(["layout-topbar", [_ctx.store.state.toggleMenu && _ctx.store.state.toggleMinimizeMenu ? 'active minimized' : _ctx.store.state.toggleMenu && !_ctx.store.state.toggleMinimizeMenu ? 'active' : '', _ctx.store.state.switchEnv === 'production' ? 'bg-environment-production' : 'bg-environment-staging']])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.store.state.toggleMenu)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            innerHTML: _ctx.icon.svg.crossAkarIcons,
            class: "btn-event-panel p-button-secondary p-button-outlined",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitToggle(false)))
          }, null, 8, ["innerHTML"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            innerHTML: _ctx.icon.svg.menuOutlineEva,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitToggle(true))),
            class: "btn-event-panel p-button-secondary p-button-outlined"
          }, null, 8, ["innerHTML"])),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString((_ctx.route.meta) ? _ctx.route.meta.group : ''), 1),
          ((_ctx.$route.path.includes('plotting-conductor') || (_ctx.$route.path.includes('roster') && !_ctx.$route.path.includes('airport'))) && !_ctx.$route.path.includes('form'))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_Button, {
                  class: "mb-2 sm:mb-0 flex gap-2",
                  onClick: _ctx.openFormDialog
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: _ctx.icon.svg.plusEva
                    }, null, 8, _hoisted_6),
                    _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.store.state?.headerButton.label), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$store.state.auth?.userinfo?.userId), 1),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$store.state.auth?.userinfo?.roles[0]), 1)
        ]),
        _createVNode(_component_ProfileDropdown)
      ])
    ])
  ], 2))
}