
// import ProfileDropdown from '@/views/layouts/navigation/ProfileDropdown.vue'
import ProfileDropdown from '@/views/layouts/navigation/ProfileAdvancePanel.vue'
import { getCurrentInstance, onMounted, ref, defineComponent, computed, reactive } from 'vue'
import { useRoute } from 'vue-router'
import {
  useStore
} from 'vuex'
import Button from 'primevue/button'
import { encryptDecriptMethods } from '@/plugins/encryptDecriptMethods'
import CountDown from '@/views/components/countdown/Countdown.vue'

export default defineComponent({
  name: 'HeaderPanel',
  components: {
    ProfileDropdown,
    Button
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const app = getCurrentInstance()
    const data = ref({}) as any
    const {
      $icon
    } = app!.appContext.config.globalProperties
    const icon = $icon
    const minimizeMenu = computed({
      get: () => store.state.toggleMinimizeMenu,
      set: (val) => {
        store.dispatch('setToggleMinimizeMenu', val)
      }
    })

    const identity = ref({
      fullname: 'PT Nusantara Jaya Distribusi',
      rolesName: 'Admin'
    })

    const emitToggle = (val: any) => {
      if (minimizeMenu.value === false && store.state.toggleMenu) {
        minimizeMenu.value = true
      } else {
        store.dispatch('setToggleMenu', val)
        minimizeMenu.value = false
      }
      console.log('store', store.state)
    }

    const showFormDialog = computed({
      get: () => store.state.headerButton.isShowDialogForm,
      set: (val) => {
        store.dispatch('setHeaderButtonActionShowDialogForm', val)
      }
    })

    const openFormDialog = () => {
      showFormDialog.value = true
      console.log('storeAfter', store.state)
    }

    onMounted(() => {
      store.dispatch('auth/fetchUserinfo')
      console.log('state', store.state)
      // store.dispatch('fetchProfile')
      // store.dispatch('fetchGlobalSettingHjex')
      // store.dispatch('globalSetting/fetchGlobalSetting')
    })

    return {
      emitToggle,
      route,
      data,
      icon,
      store,
      openFormDialog,
      identity
      // $icon
    }
  }
})
